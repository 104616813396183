























import { Component, Vue, Prop } from 'vue-property-decorator';

import Whatsapp from '@/assets/icons/social/WhatsappSvg.vue';

import { PHONE_SUPPORT_DOUBTS_WHATSAPP } from '@/constant/Contacts';

const SMALL_SIZE_ICON = '20px';
const BIG_SIZE_ICON = '30px';

const SMALL_SIZE_BUTTON = '30px';
const BIG_SIZE_BUTTON = '40px';

@Component({
  components: {
    Whatsapp
  }
})
export default class FloatingWhatsAppButton extends Vue {
  @Prop() nameRouteTutorial!: string;
  @Prop({ default: null }) params!: Record<string, any>;

  private openContent = false;

  mounted() {
    document.addEventListener('click', this.checkButton);
  }

  beforeDestroy() {
    document.removeEventListener('click', this.checkButton);
  }

  get link() {
    return `https://api.whatsapp.com/send?phone=${PHONE_SUPPORT_DOUBTS_WHATSAPP}&text=Oi,%20estou%20tendo%20problemas%20na%20plataforma`;
  }

  get sizeIcon() {
    return this.$responsive.isMobile ? SMALL_SIZE_ICON : BIG_SIZE_ICON;
  }

  get sizeButton() {
    return this.$responsive.isMobile ? SMALL_SIZE_BUTTON : BIG_SIZE_BUTTON;
  }

  checkButton(e: any) {
    const contentIsOpen = this.$refs.content as HTMLElement;

    if (
      this.openContent
      && contentIsOpen
      && !contentIsOpen.contains(e.target)
      && !this.$el.contains(e.target)
    ) this.openContent = false;
  }

  clickButton() {
    this.openContent = true;
  }
}
