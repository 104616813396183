import { render, staticRenderFns } from "./SplashScreen.vue?vue&type=template&id=3dd98062&scoped=true&"
import script from "./SplashScreen.vue?vue&type=script&lang=ts&"
export * from "./SplashScreen.vue?vue&type=script&lang=ts&"
import style0 from "./SplashScreen.scss?vue&type=style&index=0&id=3dd98062&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dd98062",
  null
  
)

export default component.exports