































import { Component, Vue } from 'vue-property-decorator';
import { differenceInDays } from 'date-fns';

import CashClock from '@/assets/icons/CashClock.vue';
import Close from '@/assets/icons/Close.vue';
import SadFace from '@/assets/icons/SadFace.vue';

const MESSAGE_EXPIRATION_PLAN = 'Seu plano expirou! Renove agora para continuar estudando com a gente.';

@Component({
  components: {
    CashClock,
    Close,
    SadFace
  }
})
export default class BannerRenewPlan extends Vue {
  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get daysRemaining() {
    const expireDate = this.plan?.expireDate;

    return differenceInDays(new Date(expireDate), new Date());
  }

  get message() {
    let message = MESSAGE_EXPIRATION_PLAN;

    if (this.daysRemaining > 0) {
      message = `Seu plano vai expirar em ${this.daysRemaining} ${
        this.daysRemaining > 1 ? 'dias' : 'dia'
      }. Você já pode renovar seu plano e continuar estudando com a gente.`;

      return message;
    }

    if (this.daysRemaining === 0) {
      message = 'Seu plano expira hoje e você já pode renová-lo!';

      return message;
    }

    return MESSAGE_EXPIRATION_PLAN;
  }

  handleRedirectRenewPlan() {
    this.$router.push({
      name: 'RenewPlan'
    });

    this.handleCloseBanner();
  }

  handleCloseBanner() {
    this.$emit('close');
  }
}
