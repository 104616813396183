






























import { Component, Mixins } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';

import { OnboardingClass } from '@/mixins/Onboarding';

@Component({
  components: {
    Modal
  }
})
export default class ModalWelcome extends Mixins(OnboardingClass) {
  async onboardingWelcomeUpdate() {
    try {
      await this.updateOnboarding(LIST_ONBOARDING_ID.FIRST_ACCESS);
      await this.timerUpdateProfile();

      this.$emit('start');
    } catch (error) {
      console.error(error);
    }
  }
}
