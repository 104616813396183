import { Component, Vue, Watch } from 'vue-property-decorator';

import { Expo, gsap } from 'gsap';
import {
  differenceInDays, endOfDay, getTime, add 
} from 'date-fns';

import PaymentService from '@/services/Payment/PaymentService';

import Theme from '@/mixins/Theme';

import Onboarding, { OnboardingInterface } from '@/share/Util/Onboarding/Onboarding';
import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';
import { ROUTER_PAGE_RENEW_PLAN } from '@/constant/Payment';

import { PAYMENT_STATUS } from '@/pages/Shopping/constants/index';

import { PaymentSuccess } from '@/pages/Shopping/interfaces/IPayment';

@Component({
  mixins: [Theme]
})
export default class DefaultLayout extends Vue {
  private transitionName = 'slide-left';

  private isAnonymous = false;
  private isShowBannerPromotion = false;
  private isShowBannerRenewPlan = false;
  private isLoadingRenewPlan = false;

  private payment: PaymentSuccess | null = null;

  private modalInstance = new ActiveModal();
  private onboarding = new Onboarding();
  private PaymentService = new PaymentService();

  mounted() {
    this.getRenewPlan();
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get getIsShowNavbar() {
    // TODO anonymous
    return this.$store.getters.isShowNavbar && this.plan;
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get profile() {
    return this.$store.getters.profile;
  }

  get isLogged() {
    return this.profile?.name;
  }

  get isSplashScreen() {
    return this.$store.getters['SplashScreen/getIsSplashScreen'];
  }

  get appStart() {
    return this.$store.getters.appStart && this.$store.getters.profile?.idstudent;
  }

  get daysRemainingPlan() {
    const planData = this.plan;
    const daysRemaining = differenceInDays(endOfDay(new Date(planData?.end_date)), new Date());

    return daysRemaining;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get isFirstAccessDate() {
    const tests = this.$store.getters.profile?.tests;

    const firstAccess = this.findOnboarding(LIST_ONBOARDING_ID.FIRST_ACCESS, tests);

    if (!firstAccess || !firstAccess.user_registry_date) return null;

    const date = add(new Date(firstAccess.user_registry_date), {
      days: 1,
      hours: 3
    });

    return getTime(date) - getTime(new Date()) > 0;
  }

  get routeIsNotFromStore() {
    const { path } = this.$route;

    return !path.includes('loja');
  }

  get showBannerPromotions() {
    return (
      (this.isShowBannerPromotion || this.isFirstAccessDate)
      && this.isPlanFree
      && this.routeIsNotFromStore
    );
  }

  get showModalRenewPlan() {
    const nameRoute = this.$route.name || '';

    return (
      !ROUTER_PAGE_RENEW_PLAN.includes(nameRoute)
      && !this.isLoadingRenewPlan
      && this.isShowBannerRenewPlan
      && this.plan?.expireDate
    );
  }

  @Watch('daysRemainingPlan')
  setModal() {
    if (!this.viewedModal(LIST_ONBOARDING_ID.FIRST_ACCESS)) {
      this.modalInstance.activeModal('ModalWelcome');
    }
  }

  @Watch('appStart', { immediate: true })
  hidePreload() {
    this.removeSplashScreen();
  }

  @Watch('$route', { immediate: true })
  init() {
    this.isAnonymous = this.$route.meta?.isAnonymous;

    this.setModal();
  }

  findOnboarding(idOnboarding: number, onboardings: OnboardingInterface[] | []) {
    return onboardings?.find((onboarding: OnboardingInterface) => onboarding.ID === idOnboarding);
  }

  viewedModal(IdModal: number) {
    if (!this.profile?.name) {
      return false;
    }

    return this.onboarding.alreadyViewedOnboarding(IdModal);
  }

  removeAllChildNodes(parent: any) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  movieClick(): void {
    this.$store.commit('setIsMovie', !this.$store.getters.isMovie);
  }

  removeSplashScreen() {
    gsap.to('#splash-screen', { opacity: 0, duration: 2, ease: Expo.easeIn }).then(() => {
      const element = document.querySelector('#splash-screen');

      if (element) {
        element.parentNode?.removeChild(element); // eslint-disable-line

        this.$store.commit('SplashScreen/setIsSplashScreen', false);
      }
    });
  }

  handleStartBanner() {
    this.isShowBannerPromotion = true;
  }

  handleCloseBannerPromotion() {
    this.isShowBannerPromotion = false;
  }

  handleCloseButton() {
    this.isShowBannerRenewPlan = false;
  }

  async getRenewPlan() {
    try {
      this.isLoadingRenewPlan = true;

      const [response] = await this.PaymentService.getPayment({
        statusExternal: PAYMENT_STATUS.CREATED
      });

      if (response) {
        this.payment = response;

        this.$store.commit('setRenewPlanPayment', response);

        this.isShowBannerRenewPlan = true;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingRenewPlan = false;
    }
  }
}
