















import { Component, Vue, Watch } from 'vue-property-decorator';

import ProgressLinearAnimated from '../ProgressLinearAnimated/index.vue';

const INSTITUTION_NAME_DEFAULT = 'Explicaê';

@Component({
  components: {
    ProgressLinearAnimated
  }
})
export default class SplashScreen extends Vue {
  private logo = ''
  private institutionName = ''

  get institution(): Record<string, any> {
    return this.$store.getters.profile.institution;
  }

  get isB2b() {
    return !!this.institution?.name;
  }

  @Watch('institution', {
    immediate: true,
    deep: true
  })
  getDataSplashScreen() {
    this.logo = this.isB2b
      ? this.institution?.image || ''
      : require('@/assets/imgs/logo/logo-explicae.svg');

    this.institutionName = this.isB2b
      ? this.institution?.name || ''
      : INSTITUTION_NAME_DEFAULT;
  }
}
